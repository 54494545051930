import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITIONS_MEDIA_HITS_CONFIG = defineFcm('petitions_media_hits_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				starterPercentage: {
					type: 'number',
				},
				maximumPerPetition: {
					type: 'number',
				},
			},
		}),
	)
	.withDefault({
		starterPercentage: 0,
		maximumPerPetition: 5,
	})
	.toConfig();
