import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_AUTO_FREE_PROMOTIONS_ROLLOUT = defineFcm('auto_free_promotions_rollout')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				traffic_percentage: { type: 'number' },
				user_safelist: {
					type: 'array',
					shape: { type: 'number' },
					optional: true,
				},
				user_blocklist: {
					type: 'array',
					shape: { type: 'number' },
					optional: true,
				},
			},
		}),
	)
	.withDefault({
		traffic_percentage: 0,
		user_safelist: [],
		user_blocklist: [],
	})
	.toConfig();
