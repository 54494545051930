import { createShapeNormalizer, normalizeBoolean } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

const normalizeBooleanRecordShape = createShapeNormalizer({
	type: 'record',
	shape: { type: 'boolean' },
});

/**
 * expected format:
 * - boolean
 * - json: { "default": true, "route-id": false, "other-route-id": false }
 */
export const FCM_CORGI_SSR_CACHE = defineFcm('corgi_ssr_cache')
	.asJson((value: unknown) => {
		try {
			return {
				default: normalizeBoolean(value),
				overrides: {},
			};
		} catch (e) {
			const objectValue = normalizeBooleanRecordShape(value);
			return {
				default: !!objectValue.default,
				overrides: Object.entries(objectValue)
					.filter(([name]) => name !== 'default')
					.reduce(
						(acc, [name, val]) => ({
							...acc,
							[name]: val,
						}),
						{},
					),
			};
		}
	})
	.withDefault({ default: false, overrides: {} })
	.toConfig();
