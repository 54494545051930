import { createShapeNormalizer, normalizeIsoDatetime } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_BANDIT_STANDALONE_DOUBLEWRITE_DATES = defineFcm('bandit_standalone_doublewrite_dates')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			shape: {
				type: 'string',
				normalizer: normalizeIsoDatetime,
			},
		}),
	)
	.withDefault({})
	.toConfig();
