import { createShapeNormalizer, normalizeBoolean } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

const shapeNormalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		// rolloutExperiment: { type: 'string' },
		// togglePUMA: { type: 'boolean' },
		// hidePUMA: { type: 'boolean' },
	},
});

export const FCM_CREAM_PETITION_UPDATE_PROMOTION_ASK = defineFcm('cream_petition_update_promotion_ask')
	.asJson((value: unknown) => {
		try {
			return normalizeBoolean(value);
		} catch (e) {
			return shapeNormalizer(value).enabled;
		}
	})
	.withDefault(false)
	.toConfig();
