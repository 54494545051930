import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_REGISTERED_VOTERS_CONFIG = defineFcm('registered_voters_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				minSignatures: { type: 'number' },
				maxSignatures: { type: 'number' },
				minPercentage: { type: 'number' },
				percentageForPetitions: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							petitionId: { type: 'string' },
							percentage: { type: 'number' },
						},
					},
				},
			},
		}),
	)
	.withDefault({
		minSignatures: 100,
		maxSignatures: 1000,
		minPercentage: 0.6,
		percentageForPetitions: [
			{
				petitionId: '123',
				percentage: 0.75,
			},
		],
	})
	.toConfig();
