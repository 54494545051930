import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAMPAIGN_SUPPORTER_VOICES_SETTINGS = defineFcm('campaign_supporter_voices_settings')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				minVideoSize: { type: 'number' },
				maxVideoSize: { type: 'number' },
				maxImageSize: { type: 'number' },
				// Note: Please update details file description for new properties added.
			},
		}),
	)
	.withDefault({
		minVideoSize: 0.04,
		maxVideoSize: 20,
		maxImageSize: 5,
	})
	.toConfig();
