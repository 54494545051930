import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_TOS_LEGAL_NOTICE_BANNER_COPY = defineFcm('tos_legal_notice_banner_copy')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				preEffectiveText: { type: 'string' },
				postEffectiveText: { type: 'string' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
