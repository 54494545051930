import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_NEW_PETITION_PAGE_REMOVE_RFS_EXPERIMENT = defineFcm('new_petition_page_remove_rfs_experiment')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				experimentName: { type: 'string', optional: true },
			},
		}),
	)
	.withDefault({
		experimentName: undefined,
	})
	.toConfig();
