import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PROMOTE_FORM_LAYOUT_EXPERIMENT = defineFcm('promote_form_layout_experiment')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
