import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_COMBO_PROMOTER_CONTRIBUTION_IMPACT_VALUES = defineFcm('combo_promoter_contribution_impact_values')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				custom_promoter_count: { type: 'number' },
				custom_contribution_amount: { type: 'number' },
				custom_additional_signatures: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
