import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_REVENUE_DYNAMIC_CPM = defineFcm('revenue_dynamic_cpm')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				multiplier_percent: { type: 'number' },
				impressions_served_threshold: { type: 'number' },
				sign_conversion_percent_threshold: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
