import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARFISH_PSF_TO_SAP_LOCAL_FLOW = defineFcm('starfish_psf_to_sap_local_flow')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				systemPrompt: { type: 'string' },
				prompt: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							replacer: {
								type: 'string',
							},
							text: {
								type: 'string',
							},
						},
					},
				},
				options: {
					optional: true,
					type: 'object',
					shape: {
						model: {
							optional: true,
							type: 'string',
						},
					},
				},
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		systemPrompt: '',
		prompt: [],
		options: undefined,
	})
	.toConfig();
