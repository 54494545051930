import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITION_PAGE_DM_CARD_CONFIG = defineFcm('petition_page_dm_card_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				newDmCtaEnabled: { type: 'boolean' },
				newDmCtaCopy: { type: 'string' },
				newDmCtaLink: { type: 'string' },
				newDmCtaSources: { type: 'array', shape: { type: 'string' } },
			},
		}),
	)
	.withDefault({
		enabled: false,
		newDmCtaEnabled: false,
		newDmCtaCopy: 'Respond now',
		newDmCtaLink: '',
		newDmCtaSources: [],
	})
	.toConfig();
