import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_POLICY_ABUSE_PETITION_REPORT = defineFcm('policy_abuse_petition_report')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				experimentName: { type: 'string', optional: true },
			},
		}),
	)
	.withDefault({
		enabled: false,
		experimentName: undefined,
	})
	.toConfig();
