import { createShapeNormalizer, normalizeRegExp } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_OPTIMIZELY_PAGES = defineFcm('optimizely_pages')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: { type: 'string', normalizer: normalizeRegExp },
		}),
	)
	.withDefault([])
	.withMerger(({ globalValue, countryValue, localeValue }) => {
		const merged = [...globalValue, ...countryValue, ...localeValue];
		return merged.filter((regex, idx) => idx === merged.findIndex((s) => s.toString() === regex.toString()));
	})
	.toConfig();
