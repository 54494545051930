import { getLocales } from '@change-corgi/config/locales';
import type { LocaleBasedExperiment } from '@change-corgi/fcm/utils';

import { createExperimentNormalizer } from './experiment';
import { createShapeNormalizer } from './shape';
import type { Normalizer } from './types';

function createLocaleBasedExperimentNormalizer<V>(
	variationNormalizer: Normalizer<V>,
): Normalizer<LocaleBasedExperiment<V>>;
function createLocaleBasedExperimentNormalizer<VN extends string, V>(
	variantNames: readonly VN[],
	variationNormalizer: Normalizer<V>,
): Normalizer<LocaleBasedExperiment<V, VN>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
function createLocaleBasedExperimentNormalizer(arg1: any, arg2?: any): Normalizer {
	const variationNames = Array.isArray(arg1) ? arg1 : undefined;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const variationNormalizer = Array.isArray(arg1) ? arg2 : arg1;

	return createShapeNormalizer({
		type: 'object',
		shape: {
			experiments: {
				type: 'record',
				shape: {
					type: 'object',
					shape: {
						locales: {
							type: 'array',
							shape: {
								type: 'enum',
								values: getLocales('nonBeta'),
							},
						},
						variants: {
							type: 'other',
							/* eslint-disable @typescript-eslint/no-unsafe-argument */
							normalizer: variationNames
								? createExperimentNormalizer(variationNames, variationNormalizer)
								: createExperimentNormalizer(variationNormalizer),
							/* eslint-enable @typescript-eslint/no-unsafe-argument */
						},
					},
				},
			},
		},
	});
}

export { createLocaleBasedExperimentNormalizer };
