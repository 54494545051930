import { getCountries } from '@change-corgi/config/countries';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_EXPORT_SIGNATURE_BLOCKLIST = defineFcm('export_signature_blocklist')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				petitionIds: { type: 'array', shape: { type: 'string' } },
				countryCodeIds: { type: 'array', shape: { type: 'enum', values: getCountries() } },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
