import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARFISH_STARTER_DASHBOARD_SIGNATURE_SOURCES = defineFcm(
	'starfish_starter_dashboard_signature_sources',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: {
					type: 'boolean',
				},
				earliest_petition_published_at: {
					type: 'string',
				},
				minimum_signature_count: {
					type: 'number',
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		earliest_petition_published_at: '2024-03-01', // 'yyyy-mm-dd'
		minimum_signature_count: 5,
	})
	.toConfig();
