import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT = defineFcm('new_petition_page_access_experiment')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				experimentName: { type: 'string', optional: true },
				mobileExperimentName: { type: 'string', optional: true },
				desktopExperimentName: { type: 'string', optional: true },
			},
		}),
	)
	.withDefault({
		enabled: false,
		experimentName: undefined,
		mobileExperimentName: undefined,
		desktopExperimentName: undefined,
	})
	.toConfig();
