import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PSF_SHARE_BUTTON_CONFIG = defineFcm('psf_share_button_config')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'enum',
				values: [
					'copy',
					'facebook',
					'instagram',
					'whatsapp',
					'twitter',
					'email',
					'facebookMessenger',
					'sms',
					'line',
					'nextdoor',
					'vk',
					'mobileNativeShare',
					'reddit',
				] as const,
			},
		}),
	)
	.withDefault([])
	.toConfig();
