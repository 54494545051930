import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARFISH_HOMEPAGE_V2 = defineFcm('starfish_homepage_v2')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				experimentName: { type: 'string' },
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		experimentName: 'starfish_homepage_v2',
	})
	.withMerger(({ countryValue, localeValue }) => {
		return {
			isEnabled:
				countryValue.isEnabled && localeValue.isEnabled && countryValue.experimentName === localeValue.experimentName,
			experimentName: countryValue.experimentName,
		};
	})
	.toConfig();
