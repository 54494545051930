import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARFISH_HOMEPAGE_V2_CONTENT = defineFcm('starfish_homepage_v2_content')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				impactVideos: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							title: {
								type: 'string',
							},
							url: {
								type: 'string',
							},
							ariaLabel: {
								type: 'string',
							},
							thumbnail: {
								type: 'string',
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		impactVideos: [],
	})
	.toConfig();
