import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_MEMBERSHIP_VENMO_EXPERIMENT = defineFcm('membership_venmo_experiment')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
