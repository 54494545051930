import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';
import type { Normalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

type CountryBannerContext = 'home' | 'petition';
type CountryBannerConfig = Readonly<{
	context: readonly CountryBannerContext[];
	content: string;
	mobileContent: string;
	/**
	 * means “this banner concerns this specific petition”
	 */
	banner_petition_id?: string;
}>;

const normalizeJsonShape = createShapeNormalizer({
	type: 'object',
	shape: {
		content: { type: 'string' },
		mobileContent: { type: 'string', optional: true },
		context: { type: 'array', shape: { type: 'enum', values: ['home', 'petition'] as const } },
		banner_petition_id: { type: 'string', optional: true },
	},
});

const normalizeCountryBanner: Normalizer<CountryBannerConfig> = (value: unknown) => {
	const parsedJson = normalizeJsonShape(value);
	return {
		...parsedJson,
		mobileContent: parsedJson.mobileContent || parsedJson.content,
	};
};

export const FCM_COUNTRY_BANNER = defineFcm('country_banner')
	.asJson(normalizeCountryBanner)
	.withDefault(undefined)
	.toConfig();
