import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_OCTOPUS_TAKO_EDITOR_CONFIG = defineFcm('octopus_tako_editor_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				toolbar: { type: 'string' },
				// Note: Please update with RichTextEditor config properties
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
