import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITION_PAGE_DM_CONSTITUENCY_CONFIG = defineFcm('petition_page_dm_constituency_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				constituents: {
					type: 'array',
					optional: true,
					shape: {
						type: 'object',
						shape: {
							petitionId: { type: 'string' },
							decisionMakerId: { type: 'string' },
							constituentCountAmongSigners: { type: 'number' },
						},
					},
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		constituents: [],
	})
	.toConfig();
