import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_COMMS_STACK_DM_ADDED_ENGAGEMENT_VALUE_PROPS_ENABLED = defineFcm(
	'comms_stack_dm_added_engagement_value_props_enabled',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				supportedLocales: {
					type: 'array',
					shape: { type: 'string' },
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		supportedLocales: [],
	})
	.toConfig();
