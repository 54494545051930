import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_MOBILE_APP_SMART_BANNER_CONFIG = defineFcm('mobile_app_smart_banner_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				appId: {
					type: 'string',
				},
				enabled: {
					type: 'boolean',
				},
			},
		}),
	)
	.withDefault({
		appId: '1144132710',
		enabled: false,
	})
	.toConfig();
