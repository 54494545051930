import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAROUSEL_MEMBERSHIP_CONFIGURATION = defineFcm('carousel_membership_configuration')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				amount_base_units: {
					type: 'number',
				},
				contribute_url: {
					type: 'string',
					optional: true,
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
