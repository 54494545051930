import { getLocales } from '@change-corgi/config/locales';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_HOMEPAGE_SUPPORT_AWARENESS = defineFcm('homepage_support_awareness')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				title: { type: 'string' },
				content: { type: 'string' },
				locales: {
					type: 'array',
					shape: {
						type: 'enum',
						values: getLocales('nonBeta'),
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
