import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_ENGAGEMENT_SIGN_FORM_MOBILE_OPT_IN_CONFIG = defineFcm('engagement_sign_form_mobile_opt_in_config')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: { type: 'string', normalizer: (country) => country.toLowerCase() },
		}),
	)
	.withDefault([])
	.toConfig();
