import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARTER_EXPERIENCE_SHARE_RECOMMENDATIONS = defineFcm('starter_experience_share_recommendations')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				experiment: { type: 'string' },
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		experiment: '',
	})
	.toConfig();
