import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAPYBARA_HOMEPAGE_V3 = defineFcm('capybara_homepage_v3')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
				layout: {
					type: 'object',
					shape: {
						hero: { type: 'boolean' },
						howTo: { type: 'boolean' },
					},
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		layout: {
			hero: false,
			howTo: false,
		},
	})
	.withMerger(({ countryValue, localeValue }) => {
		return {
			enabled: countryValue.enabled && localeValue.enabled,
			layout: {
				hero: countryValue.layout.hero && localeValue.layout.hero,
				howTo: countryValue.layout.howTo && localeValue.layout.howTo,
			},
		};
	})
	.toConfig();
