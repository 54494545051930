import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PSF_COMBO_CURRENCY_AMOUNT = defineFcm('psf_combo_currency_amount')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			keys: ['a1', 'a2', 'a3', 'a4', 'a5'] as const,
			shape: { type: 'number', optional: true },
		}),
	)
	.withDefault(undefined)
	.toConfig();
