import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_TRANSACTION_FEE_UPSELL_CONFIGURATION = defineFcm('transaction_fee_upsell_configuration')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				membership: {
					type: 'object',
					shape: {
						enabled: { type: 'boolean' },
						ratio: { optional: true, type: 'number' },
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
