import { createShapeNormalizer, normalizeIsoDatetime } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_TOS_LEGAL_NOTICE_BANNER = defineFcm('tos_legal_notice_banner')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				effectiveDate: { type: 'string', normalizer: normalizeIsoDatetime },
				preEffectiveRange: { type: 'number' },
				postEffectiveRange: { type: 'number' },
				maxDisplayCount: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
