import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PRIVACY_ANONYMOUS_USERS = defineFcm('privacy_anonymous_users')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				countries: {
					type: 'array',
					shape: { type: 'string' },
					optional: true,
				},
				users: {
					type: 'array',
					shape: { type: 'string' },
					optional: true,
				},
			},
		}),
	)
	.withDefault({
		countries: undefined,
		users: undefined,
	})
	.toConfig();
