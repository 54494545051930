import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CREAM_BONUS_PROMOTIONS_EXPERIMENTS = defineFcm('cream_bonus_promotions_experiments')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					name: { type: 'string' },
					constraints: {
						type: 'array',
						shape: { type: 'string' },
					},
					variants: {
						type: 'record',
						shape: {
							type: 'object',
							shape: {
								validationRules: { type: 'string', optional: true },
								anchorAmount: { type: 'number', optional: true },
								thresholdAmount: { type: 'number' },
								cpmBelowThreshold: { type: 'number', optional: true },
								cpmAboveThreshold: { type: 'number', optional: true },
								supportersText: { type: 'string', optional: true },
								bonusPromotionsText: { type: 'string', optional: true },
								promotionCalculatorText: { type: 'string', optional: true },
							},
						},
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
