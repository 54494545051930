import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAROUSEL_CONFIGURATION = defineFcm('carousel_configuration')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				maxPetitionsToShow: {
					type: 'number',
				},
				minPetitionsToShow: {
					type: 'record',
					keys: ['production', 'staging', 'demo', 'development'] as const,
					shape: {
						type: 'number',
					},
				},
				promotedInsertionInterval: {
					type: 'number',
				},
				userRecommendedModelName: {
					type: 'string',
					optional: true,
				},
				cardFetchStrategies: {
					type: 'array',
					shape: {
						type: 'enum',
						values: [
							'promoted',
							'user_based_recommendations',
							'petition_based_recommendations',
							'topic_petitions',
							'popular_weekly',
						] as const,
					},
				},
			},
		}),
	)
	.withDefault({
		maxPetitionsToShow: 60,
		minPetitionsToShow: {
			production: 5,
			staging: 5,
			demo: 3,
			development: 3,
		},
		promotedInsertionInterval: 2,
		userRecommendedModelName: undefined,
		cardFetchStrategies: ['topic_petitions', 'popular_weekly', 'promoted'],
	})
	.toConfig();
