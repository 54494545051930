import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PSF_SHARE_MESSAGE_BUTTON_ORDER = defineFcm('psf_share_message_button_order')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'enum',
				values: [
					'copy',
					'mobileNativeShare',
					'facebook',
					'instagram',
					'whatsapp',
					'reddit',
					'twitter',
					'email',
					'facebookMessenger',
					'sms',
					'line',
					'vk',
					// TODO older names that we should phase out
					'copylink',
					'facebook_message',
				] as const,
			},
		}),
	)
	.withDefault(['copy', 'whatsapp', 'email', 'facebookMessenger', 'twitter', 'sms'])
	.toConfig();
