import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_GRAPHQL_RESOLVER_TRACING = defineFcm('graphql_resolver_tracing')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				sampling: {
					type: 'number',
				},
				list: {
					type: 'array',
					optional: true,
					shape: {
						type: 'object',
						shape: {
							operationName: {
								type: 'string',
								optional: true,
							},
							field: {
								type: 'string',
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({
		sampling: 0,
		list: undefined,
	})
	.toConfig();
