import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITION_PROMOTE_PAGE_STATE_COLLECTION = defineFcm('petition_promote_page_state_collection')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
			},
		}),
	)
	.withDefault({ enabled: false })
	.toConfig();
