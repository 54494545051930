import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN_CONFIG = defineFcm(
	'signature_growth_google_one_tap_login_config',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
				channels: { type: 'array', shape: { type: 'string' } },
				experiment_name: { type: 'string' },
			},
		}),
	)
	.withDefault({ enabled: false, channels: [], experiment_name: '' })
	.toConfig();
