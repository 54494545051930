import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_MOBILE_APP_RELEASES = defineFcm('mobile_app_releases')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				upgrade_recommended: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							version: { type: 'string' },
							build: { type: 'string', optional: true },
						},
					},
				},
				upgrade_required: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							version: { type: 'string' },
							build: { type: 'string', optional: true },
						},
					},
				},
			},
		}),
	)
	.withDefault({
		upgrade_recommended: [],
		upgrade_required: [],
	})
	.toConfig();
