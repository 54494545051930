import type { Normalizer } from '@change-corgi/fcm/normalizers';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

const assertAmounts = ({
	amounts,
	minAmount,
	maxAmount,
	defaultAmount,
}: {
	amounts: readonly number[];
	minAmount: number;
	maxAmount: number;
	defaultAmount: number;
}) => {
	if (amounts.length !== 4) {
		throw new Error('Amounts must have 4 amounts');
	}
	if (minAmount > Math.min(...amounts)) {
		throw new Error('minAmount must be less than or equal to minimum available amount');
	}

	if (maxAmount < Math.max(...amounts)) {
		throw new Error('maxAmount must be greater than or equal to maximum available amount');
	}

	if (defaultAmount < minAmount || defaultAmount > maxAmount)
		throw new Error('defaultAmount must be in between min and max amount');
};

const subscriptionConfigurationShapeNormalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		amounts: {
			type: 'array',
			shape: {
				type: 'number',
			},
		},
		minAmount: { type: 'number' },
		maxAmount: { type: 'number' },
		defaultAmount: { type: 'number' },
		thirdPartySubscriptionService: { type: 'string', optional: true },
		subscriptionCancellationSurveyId: { type: 'string', optional: true },
		disableFeedbackForm: { type: 'boolean', optional: true },
	},
});

const normalizer: Normalizer<ReturnType<typeof subscriptionConfigurationShapeNormalizer>> = (value) => {
	const subscriptionConfiguration = subscriptionConfigurationShapeNormalizer(value);
	const { amounts, minAmount, maxAmount, defaultAmount } = subscriptionConfiguration;
	assertAmounts({ amounts, minAmount, maxAmount, defaultAmount });
	return subscriptionConfiguration;
};

export const FCM_SUBSCRIPTION_CONFIGURATION = defineFcm('subscription_configuration')
	.asJson(normalizer)
	.withDefault(undefined)
	.toConfig();
