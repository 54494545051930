import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PAYMENT_METHODS_ENABLED_FOR = defineFcm('payment_methods_enabled_for')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					payment_method_name: {
						type: 'enum',
						values: ['creditCard', 'paypal', 'sepa', 'applePay', 'googlePay'] as const,
					},
					enabled: { type: 'boolean' },
					safe_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
					block_listed_user_identifiers: { type: 'array', shape: { type: 'string' }, optional: true },
				},
			},
		}),
	)
	.withDefault(undefined)
	.withMerger(({ globalValue, countryValue, localeValue }) => {
		// if locale or country value, let's use them but allow global value to override their enabled state if enabled is false in the globalValue
		if (localeValue || countryValue) {
			return (localeValue || countryValue || []).map((value) => ({
				...value,
				enabled:
					(globalValue || []).find((g) => g.payment_method_name === value.payment_method_name && !g.enabled)?.enabled ??
					value.enabled,
			}));
		}
		return globalValue;
	})
	.toConfig();
