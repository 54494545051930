import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_SUPPORTER_VOICES_DETAILS_PAGE = defineFcm('supporter_voices_details_page')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
				activatedPercentage: { type: 'number' },
				blocklist: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'string' },
						},
					},
				},
				openVideoCreationFromPetitionDetails: { type: 'boolean', optional: true },
				sortByScore: {
					type: 'object',
					optional: true,
					shape: {
						activatedPercentage: { type: 'number', optional: true },
						petitions: {
							type: 'array',
							shape: {
								type: 'object',
								shape: {
									id: { type: 'string' },
								},
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		activatedPercentage: 0,
		blocklist: [],
		openVideoCreationFromPetitionDetails: false,
		sortByScore: { petitions: [], activatedPercentage: 0 },
	})
	.toConfig();
