import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PROMOTED_PETITION_UPDATE_EMAIL_PROMOTION_SETTINGS = defineFcm(
	'promoted_petition_update_email_promotion_settings',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				promotions: { type: 'number' },
				signatureLimit: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
