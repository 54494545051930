import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_SAP_AI_IMAGE_PROMPT = defineFcm('sap_ai_image_prompt')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				isEnabled: { type: 'boolean' },
				systemPrompt: { type: 'string' },
				mainPrompt: {
					type: 'object',
					shape: {
						replacements: { type: 'array', shape: { type: 'string' }, optional: true },
						text: { type: 'string' },
					},
				},
				options: {
					type: 'object',
					optional: true,
					shape: {
						conversationsModel: { type: 'string', optional: true },
						imageModel: { type: 'string', optional: true },
						imageSize: { type: 'string', optional: true },
					},
				},
			},
		}),
	)
	.withDefault({
		isEnabled: false,
		systemPrompt: '',
		mainPrompt: { text: '', replacements: undefined },
		options: undefined,
	})
	.toConfig();
