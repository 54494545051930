import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PACIFIC_PROMO_FREE_SAMPLE = defineFcm('pacific_promo_free_sample')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				impressions: { type: 'number' },
				amount: { type: 'number' },
				startDate: { type: 'string' },
				minSignatureCount: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
