import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_OPTIMIZELY_EVENT_SAFELIST = defineFcm('optimizely_event_safelist')
	.asJson(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'string',
			},
		}),
	)
	.withDefault([])
	.toConfig();
