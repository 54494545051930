import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITION_STARTER_MEDIA_INQUIRY_CONFIG = defineFcm('petition_starter_media_inquiry')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				url: { type: 'string' },
			},
		}),
	)
	.withDefault({
		enabled: false,
		url: '',
	})
	.toConfig();
