import { normalizeNumber, normalizeString } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CORGI_SSR_CACHE_BUSTER = defineFcm('corgi_ssr_cache_buster')
	.asJson((value: unknown) => {
		try {
			return `${normalizeNumber(value)}`;
		} catch (e) {
			return normalizeString(value);
		}
	})
	.withDefault(undefined)
	.toConfig();
