import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_DM_PROFILE_REDESIGN = defineFcm('dm_profile_redesign')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				profileCard: { type: 'boolean' },
				targetedPetitionList: { type: 'boolean' },
				sapToDm: { type: 'boolean' },
				responseList: { type: 'boolean' },
			},
		}),
	)
	.withDefault({
		profileCard: false,
		targetedPetitionList: false,
		sapToDm: false,
		responseList: false,
	})
	.withMerger(({ localeValue, countryValue }) => ({
		profileCard: localeValue.profileCard && countryValue.profileCard,
		targetedPetitionList: localeValue.targetedPetitionList && countryValue.targetedPetitionList,
		sapToDm: localeValue.sapToDm && countryValue.sapToDm,
		responseList: localeValue.responseList && countryValue.responseList,
	}))
	.toConfig();
