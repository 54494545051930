import { createLocaleBasedExperimentNormalizer, createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_GDPR_SIGN_OPT_IN_COPY = defineFcm('gdpr_sign_opt_in_copy')
	.asJson(
		createLocaleBasedExperimentNormalizer(
			createShapeNormalizer({
				type: 'object',
				shape: {
					prompt: { type: 'string', optional: true },
					opt_in: { type: 'string', optional: true },
					opt_out: { type: 'string', optional: true },
					opt_out_warning: { type: 'string', optional: true },
					form_error: { type: 'string', optional: true },
				},
			}),
		),
	)
	.withDefault(undefined)
	.toConfig();
