import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_REVENUE_MANUALLY_PROMOTED_VICTORIOUS_PETITION_DATA = defineFcm(
	'revenue_manually_promoted_victorious_petition_data',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				petition_ids: {
					type: 'array',
					shape: {
						type: 'number',
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
