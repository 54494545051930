import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_HOMEPAGE_NOTICE = defineFcm('homepage_notice')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				desktop_prefix: { type: 'string' },
				desktop_copy: { type: 'string' },
				desktop_link_text: { type: 'string' },
				mobile_prefix: { type: 'string' },
				mobile_copy: { type: 'string' },
				mobile_link_text: { type: 'string' },
				url: { type: 'string' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
