import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_GATEWAY_ROUTER_STRIPE_CREATE_PAYMENT_METHOD_CONFIG = defineFcm(
	'gateway_router_stripe_create_payment_method_config',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				tokenization_account: {
					type: 'object',
					shape: {
						id: { type: 'string' },
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
