import type { Simplify } from 'type-fest';

import {
	createEnumNormalizer,
	createJsonNormalizer,
	normalizeBoolean,
	normalizeNumber,
	normalizeString,
} from '@change-corgi/fcm/normalizers';

import type { FcmConfig, Merger, SimplifyWhenEqual } from './types';

type FactoryFcmConfigWithoutMerger<N extends string, T, D> = Simplify<
	Readonly<{ name: N } & Omit<FcmConfig<T, D>, 'name' | 'merger'>>
>;
type FactoryFcmConfigWithMerger<N extends string, T, D> = Simplify<
	Readonly<{ name: N; merger: Merger<SimplifyWhenEqual<T, D>> } & Omit<FcmConfig<T, D>, 'name' | 'merger'>>
>;

// using this approach to allow for intellisense completion
type JsonWithDefaultFn<N extends string, T> = {
	(defaultValue: T): {
		withMerger: (merger: Merger<T>) => {
			toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithMerger<N, T, T>;
		};
		toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithoutMerger<N, T, T>;
	};
	(defaultValue: undefined): {
		withMerger: (merger: Merger<T | undefined>) => {
			toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithMerger<N, T, undefined>;
		};
		toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithoutMerger<N, T, undefined>;
	};
};

type FactoryMethods<N extends string> = {
	asBoolean: () => {
		withDefault: (defaultValue: boolean) => {
			withMerger: (merger: Merger<boolean>) => {
				toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithMerger<N, boolean, boolean>;
			};
			toConfig: (options?: { deprecated?: string }) => FactoryFcmConfigWithoutMerger<N, boolean, boolean>;
		};
	};
	asNumber: () => {
		withDefault: <DEF extends number | undefined>(
			defaultValue: DEF,
		) => {
			toConfig: (options?: {
				deprecated?: string;
			}) => FactoryFcmConfigWithoutMerger<N, number, DEF extends undefined ? undefined : number>;
		};
	};
	asString: () => {
		withDefault: <DEF extends string | undefined>(
			defaultValue: DEF,
		) => {
			toConfig: (options?: {
				deprecated?: string;
			}) => FactoryFcmConfigWithoutMerger<N, string, DEF extends undefined ? undefined : string>;
		};
	};
	asEnum: <T extends string>(
		values: readonly T[],
	) => {
		withDefault: <DEF extends T | undefined>(
			defaultValue: DEF,
		) => {
			toConfig: (options?: {
				deprecated?: string;
			}) => FactoryFcmConfigWithoutMerger<N, T, DEF extends undefined ? undefined : T>;
		};
	};
	asJson: <T>(normalizeParsedValue: (parsedValue: unknown) => T) => {
		withDefault: JsonWithDefaultFn<N, T>;
	};
};

// eslint-disable-next-line max-lines-per-function
export function defineFcm<N extends string>(name: N): FactoryMethods<N> {
	return {
		asBoolean: () => ({
			withDefault: (defaultValue: boolean) => ({
				withMerger: (merger: Merger<boolean>) => ({
					toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
						({
							name,
							normalizer: normalizeBoolean,
							defaultValue,
							merger,
							deprecated,
						}) as FactoryFcmConfigWithMerger<N, boolean, boolean>,
				}),
				toConfig: ({ deprecated }: { deprecated?: string } = {}) => ({
					name,
					normalizer: normalizeBoolean,
					defaultValue,
					deprecated,
				}),
			}),
		}),
		asNumber: () => ({
			withDefault: <DEF extends number | undefined>(defaultValue: DEF) => ({
				toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
					({ name, normalizer: normalizeNumber, defaultValue, deprecated }) as FactoryFcmConfigWithoutMerger<
						N,
						number,
						DEF extends undefined ? undefined : number
					>,
			}),
		}),
		asString: () => ({
			withDefault: <DEF extends string | undefined>(defaultValue: DEF) => ({
				toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
					({
						name,
						normalizer: normalizeString,
						defaultValue,
						deprecated,
					}) as FactoryFcmConfigWithoutMerger<N, string, DEF extends undefined ? undefined : string>,
			}),
		}),
		asEnum: <T extends string>(values: readonly T[]) => ({
			withDefault: <DEF extends T | undefined>(defaultValue: DEF) => ({
				toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
					({
						name,
						normalizer: createEnumNormalizer(values),
						defaultValue,
						deprecated,
					}) as FactoryFcmConfigWithoutMerger<N, T, DEF extends undefined ? undefined : T>,
			}),
		}),
		asJson: <T>(normalizeParsedValue: (parsedValue: unknown) => T) => ({
			withDefault: (<DEF extends T | undefined>(defaultValue: DEF) => ({
				withMerger: (merger: Merger<T | DEF extends undefined ? undefined : T>) => ({
					toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
						({
							name,
							normalizer: createJsonNormalizer(normalizeParsedValue),
							defaultValue,
							merger,
							deprecated,
						}) as FactoryFcmConfigWithMerger<N, T, DEF extends undefined ? undefined : T>,
				}),
				toConfig: ({ deprecated }: { deprecated?: string } = {}) =>
					({
						name,
						normalizer: createJsonNormalizer(normalizeParsedValue),
						defaultValue,
						deprecated,
					}) as FactoryFcmConfigWithoutMerger<N, T, DEF extends undefined ? undefined : T>,
			})) as JsonWithDefaultFn<N, T>,
		}),
	};
}
