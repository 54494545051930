import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CONTEXTUAL_PROMOTION_BUTTONS_BANDIT_CONFIG = defineFcm('contextual_promotion_buttons_bandit_config')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			shape: {
				type: 'array',
				shape: { type: 'number' },
			},
		}),
	)
	.withDefault({})
	.toConfig();
