import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_DM_STATUS = defineFcm('dm_status')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				enabled: { type: 'boolean' },
				specificPetitions: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'string' },
							dm_status: {
								type: 'array',
								shape: {
									type: 'object',
									shape: {
										id: { type: 'string' },
										petition_update: { type: 'number' },
									},
								},
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({
		enabled: false,
		specificPetitions: [],
	})
	.toConfig();
