import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_NAFTA_PSF_SHARE_COPY_BANDIT_TRAFFIC_CONFIG = defineFcm('nafta_psf_share_copy_bandit_traffic_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				message: { type: 'number' },
				skip: { type: 'number' },
				initial: { type: 'number' },
			},
		}),
	)
	.withDefault({
		message: 0,
		skip: 0,
		initial: 0,
	})
	.toConfig();
