import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARTER_DASHBOARD_CONTACT_DM = defineFcm('starter_dashboard_contact_dm')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			optional: true,
			shape: {
				emailEnabled: { type: 'boolean' },
				emailSubjectLine: { type: 'string', optional: true },
				emailBody: { type: 'array', optional: true, shape: { type: 'string' } },
			},
		}),
	)
	.withDefault({
		emailEnabled: false,
		emailSubjectLine: '',
		emailBody: [],
	})
	.toConfig();
