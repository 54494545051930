import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PPMU_CUSTOM_AMOUNT_EXPERIMENT = defineFcm('ppmu_custom_amount_experiment')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				experiment_name: { type: 'string' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
