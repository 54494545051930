import { getLocales } from '@change-corgi/config/locales';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_STARFISH_SHARE_VISUALS = defineFcm('starfish_share_visuals')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				enabled: { type: 'boolean' },
				locales: { type: 'array', shape: { type: 'enum', values: getLocales() } },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
