import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PACIFIC_PETITION_PROMOTION_CONFIG = defineFcm('pacific_petition_promotion_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				block_all: { type: 'boolean', optional: true },
				block_petition_ids: { type: 'array', shape: { type: 'number' } },
			},
		}),
	)
	.withDefault({
		block_all: false,
		block_petition_ids: [],
	})
	.toConfig();
