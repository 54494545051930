import { getLocales } from '@change-corgi/config/locales';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_FUTURE_SAP_MEDIA_DISCLAIMER = defineFcm('future_sap_media_disclaimer')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			keys: getLocales(),
			shape: { type: 'boolean', optional: true },
		}),
	)
	.withDefault({})
	.toConfig();
