import type { Normalizer } from '@change-corgi/fcm/normalizers';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

const idNormalizer: Normalizer<string> = (value) => {
	if (typeof value === 'string') return value;
	if (typeof value === 'number') return String(value);
	throw new Error('Invalid user id/uuid');
};

export const FCM_PROMOTION_COUNTRY_GRADUAL_OPENING = defineFcm('promotion_country_gradual_opening')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				traffic_percentage: { type: 'number' },
				safe_listed_user_identifiers: {
					type: 'array',
					shape: { type: 'string', normalizer: idNormalizer },
					optional: true,
				},
				block_listed_user_identifiers: {
					type: 'array',
					shape: { type: 'string', normalizer: idNormalizer },
					optional: true,
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
