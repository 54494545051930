import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_PETITION_SHOW_DISPLAY_CHAT_DAYS_POST_PUBLISH = defineFcm(
	'petition_show_display_chat_days_post_publish',
)
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				start_day: { type: 'number' },
				end_day: { type: 'number' },
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
