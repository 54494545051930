import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_BONUS_PROMOTIONS_EXPERIMENTS = defineFcm('bonus_promotions_experiments')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				name: { type: 'string' },
				variants: {
					type: 'record',
					shape: {
						type: 'object',
						shape: {
							anchorAmount: { type: 'number' },
							thresholdAmount: { type: 'number' },
							cpmBelowThreshold: { type: 'number' },
							cpmAboveThreshold: { type: 'number' },
						},
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
