import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

type Id = 'a1' | 'a2' | 'a3' | 'a4' | 'a5';
const IDS: readonly Id[] = ['a1', 'a2', 'a3', 'a4', 'a5'];

export const FCM_PSF_COMBO_BANDIT_CONFIG = defineFcm('psf_combo_bandit_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				variants: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							name: { type: 'enum', values: IDS },
							amount_id: { type: 'enum', values: IDS },
						},
					},
				},
			},
		}),
	)
	.withDefault(undefined)
	.toConfig();
