import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_SHOW_SAVE_PAYMENT_CHECKBOX_CONFIG = defineFcm('show_save_payment_checkbox_config')
	.asJson(
		createShapeNormalizer({
			type: 'object',
			shape: {
				stripe: { type: 'boolean', optional: true },
				paypal: { type: 'boolean', optional: true },
			},
		}),
	)
	.withDefault({
		stripe: true,
		paypal: true,
	})
	.toConfig();
