import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

/**
 * expected format:
 * - json: {"sepa_debit": 20000, "payment_method_type2": 10000 }
 * - supported payment_method_type enums - `credit_card`, `sepa_debit`, `google_pay`, `apple_pay`, `paypal`
 */
export const FCM_PAYMENTS_REFUNDS_MAX_AMOUNTS = defineFcm('payments_refunds_max_amounts')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			keys: ['credit_card', 'sepa_debit', 'google_pay', 'apple_pay', 'paypal'] as const,
			shape: { type: 'number' },
		}),
	)
	.withDefault({})
	.toConfig();
