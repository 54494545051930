import { getLocales } from '@change-corgi/config/locales';
import { createShapeNormalizer } from '@change-corgi/fcm/normalizers';

import { defineFcm } from '../shared/factory';

export const FCM_CAPYBARA_HOMEPAGE_V3_CONTENT = defineFcm('capybara_homepage_v3_content')
	.asJson(
		createShapeNormalizer({
			type: 'record',
			keys: getLocales(),
			shape: {
				type: 'object',
				shape: {
					tips: {
						type: 'array',
						shape: {
							type: 'object',
							shape: {
								title: { type: 'string' },
								guideLink: { type: 'string' },
							},
						},
					},
					topImpactStories: {
						type: 'array',
						shape: {
							type: 'object',
							shape: {
								petitionId: { type: 'string' },
								summary: { type: 'string' },
								imageUrl: { type: 'string' },
							},
						},
					},
				},
			},
		}),
	)
	.withDefault({})
	.toConfig();
